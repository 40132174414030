<template>
    <div>
        <p v-html="$t('faqTab.gettingStarted.paypal.portal')" />
        <a :href="portalLink" target="_blank">{{ portalLink }}</a>
        <p class="mb-4" v-html="$t('faqTab.gettingStarted.paypal.chooseCreate')" />
        <img class="w-100" src="../assets/step5.1.png" :alt="$t('faqTab.gettingStarted.paypal.enough')" >
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.movedToNext')" />
        <img class="w-100" src="../assets/step5.2.png" :alt="$t('faqTab.gettingStarted.paypal.movedToNext')" >
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.message')" />
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.receiveUsername')" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            portalLink: "https://apollogroup.tv/portal/"
        }
    }
}
</script>